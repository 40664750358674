import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        fill: theme.palette.foreground.default,
        "&:hover": {
            fill: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (

        // <svg width="29pt" height="25pt" viewBox="0 0 11 27" xmlns="http://www.w3.org/2000/svg" className={classes.svgHover}>

        //     <path d="M 0.029 18.838 L 0.029 16.538 L 3.75 16.538 L 3.75 19.131 A 20.068 20.068 0 0 0 3.773 20.128 Q 3.818 21.03 3.952 21.621 A 3.229 3.229 0 0 0 4.116 22.156 Q 4.42 22.878 5.166 23.002 A 2.001 2.001 0 0 0 5.493 23.028 A 2.186 2.186 0 0 0 5.982 22.977 Q 6.592 22.837 6.834 22.31 Q 7.163 21.592 7.163 19.966 A 7.614 7.614 0 0 0 7.039 18.556 A 5.644 5.644 0 0 0 6.577 17.132 A 7.688 7.688 0 0 0 5.947 16.073 Q 5.444 15.357 4.717 14.605 L 2.813 12.642 A 12.916 12.916 0 0 1 1.501 11.044 A 10.393 10.393 0 0 1 0.74 9.756 A 7.018 7.018 0 0 1 0.104 7.705 A 9.164 9.164 0 0 1 0 6.299 Q 0 3.186 1.169 1.632 A 3.999 3.999 0 0 1 1.231 1.553 Q 2.189 0.343 4.224 0.076 A 9.542 9.542 0 0 1 5.464 0 A 7.714 7.714 0 0 1 6.918 0.127 Q 8.748 0.479 9.529 1.809 A 6.445 6.445 0 0 1 10.154 3.32 Q 10.563 4.765 10.589 6.812 A 22.048 22.048 0 0 1 10.591 7.09 L 10.591 8.687 L 6.973 8.687 L 6.973 6.841 Q 6.973 4.966 6.651 4.153 Q 6.38 3.471 5.692 3.361 A 1.773 1.773 0 0 0 5.413 3.34 A 2.142 2.142 0 0 0 4.914 3.394 A 1.301 1.301 0 0 0 4.094 3.955 A 2.052 2.052 0 0 0 3.857 4.477 Q 3.702 4.978 3.692 5.7 A 7.287 7.287 0 0 0 3.692 5.801 A 6.008 6.008 0 0 0 3.738 6.572 Q 3.829 7.278 4.102 7.764 A 5.302 5.302 0 0 0 4.403 8.235 Q 4.829 8.836 5.581 9.639 L 7.705 11.939 A 15.657 15.657 0 0 1 9.101 13.671 A 11.764 11.764 0 0 1 10.115 15.44 A 9.052 9.052 0 0 1 10.92 18.901 A 10.56 10.56 0 0 1 10.928 19.307 A 17.185 17.185 0 0 1 10.832 21.193 Q 10.618 23.123 9.928 24.314 A 4.552 4.552 0 0 1 9.712 24.654 A 3.732 3.732 0 0 1 7.871 26.015 Q 6.858 26.382 5.457 26.382 Q 3.511 26.382 2.322 25.593 A 3.662 3.662 0 0 1 1.223 24.456 Q 0.053 22.568 0.03 18.983 A 22.509 22.509 0 0 1 0.029 18.838 Z"
        //         vector-effect="non-scaling-stroke" />
        // </svg>

        <svg
            width="28pt"
            height="32pt"
            viewBox="-38 -15 607 411"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.svgHover}
        >
            <path
                d="m 5270,3290 c -211,-6 -542,-36 -870,-80 -41,-5 -192,-23 -335,-40 -704,-80 -1051,-147 -1670,-323 -126,-36 -259,-73 -295,-81 l -65,-15 70,5 c 68,4 146,14 445,55 147,21 480,76 623,104 42,8 82,15 88,15 6,0 8,-21 4,-57 -3,-32 -12,-141 -20,-243 -16,-193 -70,-532 -87,-544 -7,-5 -369,-80 -488,-101 -111,-19 -125,-24 -128,-43 -6,-40 97,-39 358,3 148,24 220,29 220,15 0,-17 -69,-204 -135,-364 -90,-222 -126,-333 -115,-361 10,-28 54,-43 79,-28 9,5 24,25 33,44 37,73 188,471 227,598 23,74 43,137 45,139 5,4 37,9 371,51 132,16 357,42 500,56 247,25 508,61 623,85 53,12 73,39 30,41 -13,0 -95,4 -183,8 -290,14 -658,-14 -1077,-80 -106,-16 -204,-33 -217,-36 -23,-6 -24,-4 -17,28 29,137 52,374 62,623 5,114 11,179 18,182 28,9 402,59 711,95 552,63 950,119 1170,165 264,54 280,58 280,73 0,19 15,18 -255,11 z"
                transform="matrix(0.12033834,0,0,-0.17985795,-74.058857,606.18244)"
                fill="#00000" />
            <path
                d="m 2660,2389 c -13,-6 -27,-14 -31,-20 -9,-15 -222,-65 -402,-94 -89,-15 -297,-51 -462,-80 -165,-30 -352,-63 -415,-74 -63,-11 -164,-29 -225,-40 -60,-11 -164,-27 -229,-36 -149,-21 -166,-27 -166,-60 0,-36 29,-39 190,-21 281,32 731,15 1020,-38 321,-60 661,-197 637,-259 -9,-24 -96,-65 -185,-87 -173,-44 -501,-70 -884,-70 -270,0 -408,7 -408,20 0,3 66,18 148,33 81,15 208,38 282,51 131,24 408,86 560,125 79,20 74,28 -7,11 -374,-75 -700,-134 -833,-150 -274,-33 -330,-48 -330,-90 0,-30 36,-47 135,-64 170,-30 408,-40 612,-27 332,22 515,49 703,102 187,54 250,95 250,163 0,34 -6,46 -48,85 -154,149 -608,271 -1006,271 -60,0 -107,2 -105,5 8,7 416,91 714,146 281,52 409,81 460,107 56,28 85,55 85,79 0,25 -17,29 -60,12 z"
                transform="matrix(0.12033834,0,0,-0.17985795,-74.058857,606.18244)"
                fill="#00000" />
        </svg>



    );
};
